/*Rest Classes
---------------------------------------------------------------------------------------------------------------*/

/* Standard Spacing Classes
---------------------------------------------------------------------------------------------------------------*/

.mb-2p {
  margin-bottom: 2px !important;
}

.letter-space-1 {
  letter-spacing: 1px !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.m-3 {
  margin: 30px !important;
}

.m-4 {
  margin: 40px !important;
}

.m-5 {
  margin: 50px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-2 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-3 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-4 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-5 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-2 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-3 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-5 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mr-8 {
  margin-right: 80px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mr-4p {
  margin-right: 4px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 5px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.p-3 {
  padding: 30px !important;
}

.p-4 {
  padding: 40px !important;
}

.p-5 {
  padding: 50px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-2 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-3 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.px-4 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-5 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-02 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-06 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-1 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-2 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-3 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-4 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-5 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-07 {
  padding-left: 7px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.m--1 {
  margin: -10px !important;
}

.m--2 {
  margin: -20px !important;
}

.m--3 {
  margin: -30px !important;
}

.mt--1 {
  margin-top: -10px !important;
}

.mt--2 {
  margin-top: -20px !important;
}

.mt--3 {
  margin-top: -30px !important;
}

.mt--15 {
  margin-top: -15px !important;
}

.mb--1 {
  margin-bottom: -10px !important;
}

.mb--2 {
  margin-bottom: -20px !important;
}

.mb--3 {
  margin-bottom: -30px !important;
}

.ml--1 {
  margin-left: -10px !important;
}

.ml--2 {
  margin-left: -20px !important;
}

.ml--3 {
  margin-left: -30px !important;
}

.ml--98 {
  margin-left: -980px !important;
}

.mr--1 {
  margin-right: -10px !important;
}

.mr--2 {
  margin-right: -20px !important;
}

.mr--3 {
  margin-right: -30px !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.gap {
  gap: 10px !important;
}

@media (min-width: 1536px) {
  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 10px !important;
  }

  .m-xl-2 {
    margin: 20px !important;
  }

  .m-xl-3 {
    margin: 30px !important;
  }

  .m-xl-4 {
    margin: 40px !important;
  }

  .m-xl-5 {
    margin: 50px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-xl-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-xl-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-xl-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-xl-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-xl-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xl-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-xl-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xl-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 10px !important;
  }

  .mt-xl-2 {
    margin-top: 20px !important;
  }

  .mt-xl-3 {
    margin-top: 30px !important;
  }

  .mt-xl-4 {
    margin-top: 40px !important;
  }

  .mt-xl-5 {
    margin-top: 50px !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: 10px !important;
  }

  .mr-xl-2 {
    margin-right: 20px !important;
  }

  .mr-xl-3 {
    margin-right: 30px !important;
  }

  .mr-xl-4 {
    margin-right: 40px !important;
  }

  .mr-xl-5 {
    margin-right: 50px !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 10px !important;
  }

  .mb-xl-2 {
    margin-bottom: 20px !important;
  }

  .mb-xl-3 {
    margin-bottom: 30px !important;
  }

  .mb-xl-4 {
    margin-bottom: 40px !important;
  }

  .mb-xl-5 {
    margin-bottom: 50px !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: 10px !important;
  }

  .ml-xl-2 {
    margin-left: 20px !important;
  }

  .ml-xl-3 {
    margin-left: 30px !important;
  }

  .ml-xl-4 {
    margin-left: 40px !important;
  }

  .ml-xl-5 {
    margin-left: 50px !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 10px !important;
  }

  .p-xl-2 {
    padding: 20px !important;
  }

  .p-xl-3 {
    padding: 30px !important;
  }

  .p-xl-4 {
    padding: 40px !important;
  }

  .p-xl-5 {
    padding: 50px !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-xl-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-xl-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-xl-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-xl-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-xl-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xl-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-xl-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xl-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 10px !important;
  }

  .pt-xl-2 {
    padding-top: 20px !important;
  }

  .pt-xl-3 {
    padding-top: 30px !important;
  }

  .pt-xl-4 {
    padding-top: 40px !important;
  }

  .pt-xl-5 {
    padding-top: 50px !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pr-xl-1 {
    padding-right: 10px !important;
  }

  .pr-xl-2 {
    padding-right: 20px !important;
  }

  .pr-xl-3 {
    padding-right: 30px !important;
  }

  .pr-xl-4 {
    padding-right: 40px !important;
  }

  .pr-xl-5 {
    padding-right: 50px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 10px !important;
  }

  .pb-xl-2 {
    padding-bottom: 20px !important;
  }

  .pb-xl-3 {
    padding-bottom: 30px !important;
  }

  .pb-xl-4 {
    padding-bottom: 40px !important;
  }

  .pb-xl-5 {
    padding-bottom: 50px !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .pl-xl-1 {
    padding-left: 10px !important;
  }

  .pl-xl-2 {
    padding-left: 20px !important;
  }

  .pl-xl-3 {
    padding-left: 30px !important;
  }

  .pl-xl-4 {
    padding-left: 40px !important;
  }

  .pl-xl-5 {
    padding-left: 50px !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }
}

@media (min-width: 1200px) {
  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 10px !important;
  }

  .m-lg-2 {
    margin: 20px !important;
  }

  .m-lg-3 {
    margin: 30px !important;
  }

  .m-lg-4 {
    margin: 40px !important;
  }

  .m-lg-5 {
    margin: 50px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-lg-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-lg-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-lg-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-lg-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-lg-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-lg-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-lg-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-lg-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 10px !important;
  }

  .mt-lg-2 {
    margin-top: 20px !important;
  }

  .mt-lg-3 {
    margin-top: 30px !important;
  }

  .mt-lg-4 {
    margin-top: 40px !important;
  }

  .mt-lg-5 {
    margin-top: 50px !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: 10px !important;
  }

  .mr-lg-2 {
    margin-right: 20px !important;
  }

  .mr-lg-3 {
    margin-right: 30px !important;
  }

  .mr-lg-4 {
    margin-right: 40px !important;
  }

  .mr-lg-5 {
    margin-right: 50px !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 10px !important;
  }

  .mb-lg-2 {
    margin-bottom: 20px !important;
  }

  .mb-lg-3 {
    margin-bottom: 30px !important;
  }

  .mb-lg-4 {
    margin-bottom: 40px !important;
  }

  .mb-lg-5 {
    margin-bottom: 50px !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: 10px !important;
  }

  .ml-lg-2 {
    margin-left: 20px !important;
  }

  .ml-lg-3 {
    margin-left: 30px !important;
  }

  .ml-lg-4 {
    margin-left: 40px !important;
  }

  .ml-lg-5 {
    margin-left: 50px !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 10px !important;
  }

  .p-lg-2 {
    padding: 20px !important;
  }

  .p-lg-3 {
    padding: 30px !important;
  }

  .p-lg-4 {
    padding: 40px !important;
  }

  .p-lg-5 {
    padding: 50px !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-lg-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-lg-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-lg-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-lg-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-lg-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-lg-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-lg-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-lg-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 10px !important;
  }

  .pt-lg-2 {
    padding-top: 20px !important;
  }

  .pt-lg-3 {
    padding-top: 30px !important;
  }

  .pt-lg-4 {
    padding-top: 40px !important;
  }

  .pt-lg-5 {
    padding-top: 50px !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pr-lg-1 {
    padding-right: 10px !important;
  }

  .pr-lg-2 {
    padding-right: 20px !important;
  }

  .pr-lg-3 {
    padding-right: 30px !important;
  }

  .pr-lg-4 {
    padding-right: 40px !important;
  }

  .pr-lg-5 {
    padding-right: 50px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 10px !important;
  }

  .pb-lg-2 {
    padding-bottom: 20px !important;
  }

  .pb-lg-3 {
    padding-bottom: 30px !important;
  }

  .pb-lg-4 {
    padding-bottom: 40px !important;
  }

  .pb-lg-5 {
    padding-bottom: 50px !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pl-lg-1 {
    padding-left: 10px !important;
  }

  .pl-lg-2 {
    padding-left: 20px !important;
  }

  .pl-lg-3 {
    padding-left: 30px !important;
  }

  .pl-lg-4 {
    padding-left: 40px !important;
  }

  .pl-lg-5 {
    padding-left: 50px !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }
}

@media (min-width: 900px) {
  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 10px !important;
  }

  .m-md-2 {
    margin: 20px !important;
  }

  .m-md-3 {
    margin: 30px !important;
  }

  .m-md-4 {
    margin: 40px !important;
  }

  .m-md-5 {
    margin: 50px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-md-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-md-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-md-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-md-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-md-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-md-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-md-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-md-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 10px !important;
  }

  .mt-md-2 {
    margin-top: 20px !important;
  }

  .mt-md-3 {
    margin-top: 30px !important;
  }

  .mt-md-4 {
    margin-top: 40px !important;
  }

  .mt-md-5 {
    margin-top: 50px !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: 10px !important;
  }

  .mr-md-2 {
    margin-right: 20px !important;
  }

  .mr-md-3 {
    margin-right: 30px !important;
  }

  .mr-md-4 {
    margin-right: 40px !important;
  }

  .mr-md-5 {
    margin-right: 50px !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 10px !important;
  }

  .mb-md-2 {
    margin-bottom: 20px !important;
  }

  .mb-md-3 {
    margin-bottom: 30px !important;
  }

  .mb-md-4 {
    margin-bottom: 40px !important;
  }

  .mb-md-5 {
    margin-bottom: 50px !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: 10px !important;
  }

  .ml-md-2 {
    margin-left: 20px !important;
  }

  .ml-md-3 {
    margin-left: 30px !important;
  }

  .ml-md-4 {
    margin-left: 40px !important;
  }

  .ml-md-5 {
    margin-left: 50px !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 10px !important;
  }

  .p-md-2 {
    padding: 20px !important;
  }

  .p-md-3 {
    padding: 30px !important;
  }

  .p-md-4 {
    padding: 40px !important;
  }

  .p-md-5 {
    padding: 50px !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-md-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-md-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-md-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-md-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-md-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-md-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-md-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-md-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 10px !important;
  }

  .pt-md-2 {
    padding-top: 20px !important;
  }

  .pt-md-3 {
    padding-top: 30px !important;
  }

  .pt-md-4 {
    padding-top: 40px !important;
  }

  .pt-md-5 {
    padding-top: 50px !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-1 {
    padding-right: 10px !important;
  }

  .pr-md-2 {
    padding-right: 20px !important;
  }

  .pr-md-3 {
    padding-right: 30px !important;
  }

  .pr-md-4 {
    padding-right: 40px !important;
  }

  .pr-md-5 {
    padding-right: 50px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 10px !important;
  }

  .pb-md-2 {
    padding-bottom: 20px !important;
  }

  .pb-md-3 {
    padding-bottom: 30px !important;
  }

  .pb-md-4 {
    padding-bottom: 40px !important;
  }

  .pb-md-5 {
    padding-bottom: 50px !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-1 {
    padding-left: 10px !important;
  }

  .pl-md-2 {
    padding-left: 20px !important;
  }

  .pl-md-3 {
    padding-left: 30px !important;
  }

  .pl-md-4 {
    padding-left: 40px !important;
  }

  .pl-md-5 {
    padding-left: 50px !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }
}

@media (min-width: 600px) {
  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 10px !important;
  }

  .m-sm-2 {
    margin: 20px !important;
  }

  .m-sm-3 {
    margin: 30px !important;
  }

  .m-sm-4 {
    margin: 40px !important;
  }

  .m-sm-5 {
    margin: 50px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .mx-sm-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .mx-sm-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .mx-sm-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .mx-sm-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-sm-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-sm-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-sm-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-sm-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 10px !important;
  }

  .mt-sm-2 {
    margin-top: 20px !important;
  }

  .mt-sm-3 {
    margin-top: 30px !important;
  }

  .mt-sm-4 {
    margin-top: 40px !important;
  }

  .mt-sm-5 {
    margin-top: 50px !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: 10px !important;
  }

  .mr-sm-2 {
    margin-right: 20px !important;
  }

  .mr-sm-3 {
    margin-right: 30px !important;
  }

  .mr-sm-4 {
    margin-right: 40px !important;
  }

  .mr-sm-5 {
    margin-right: 50px !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 10px !important;
  }

  .mb-sm-2 {
    margin-bottom: 20px !important;
  }

  .mb-sm-3 {
    margin-bottom: 30px !important;
  }

  .mb-sm-4 {
    margin-bottom: 40px !important;
  }

  .mb-sm-5 {
    margin-bottom: 50px !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: 10px !important;
  }

  .ml-sm-2 {
    margin-left: 20px !important;
  }

  .ml-sm-3 {
    margin-left: 30px !important;
  }

  .ml-sm-4 {
    margin-left: 40px !important;
  }

  .ml-sm-5 {
    margin-left: 50px !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 10px !important;
  }

  .p-sm-2 {
    padding: 20px !important;
  }

  .p-sm-3 {
    padding: 30px !important;
  }

  .p-sm-4 {
    padding: 40px !important;
  }

  .p-sm-5 {
    padding: 50px !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .px-sm-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .px-sm-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .px-sm-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .px-sm-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-sm-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-sm-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-sm-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-sm-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 10px !important;
  }

  .pt-sm-2 {
    padding-top: 20px !important;
  }

  .pt-sm-3 {
    padding-top: 30px !important;
  }

  .pt-sm-4 {
    padding-top: 40px !important;
  }

  .pt-sm-5 {
    padding-top: 50px !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-1 {
    padding-right: 10px !important;
  }

  .pr-sm-2 {
    padding-right: 20px !important;
  }

  .pr-sm-3 {
    padding-right: 30px !important;
  }

  .pr-sm-4 {
    padding-right: 40px !important;
  }

  .pr-sm-5 {
    padding-right: 50px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 10px !important;
  }

  .pb-sm-2 {
    padding-bottom: 20px !important;
  }

  .pb-sm-3 {
    padding-bottom: 30px !important;
  }

  .pb-sm-4 {
    padding-bottom: 40px !important;
  }

  .pb-sm-5 {
    padding-bottom: 50px !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-1 {
    padding-left: 10px !important;
  }

  .pl-sm-2 {
    padding-left: 20px !important;
  }

  .pl-sm-3 {
    padding-left: 30px !important;
  }

  .pl-sm-4 {
    padding-left: 40px !important;
  }

  .pl-sm-5 {
    padding-left: 50px !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-none {
    display: none !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }
}
