.center-body {
  background: #f5f5f5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
}

.loader-shape-3 {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
}
.loader-shape-3:after {
  width: 30px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(216, 71, 13, 0.541);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px;
}
.loader-shape-3:before {
  width: 20px;
  height: 20px;
  border-radius: 100% 100% 100% 0;
  box-shadow: 0px 0px 0px 2px rgb(224, 103, 4);
  animation: anm-SL-3-move 1s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-46deg);
  margin-top: 20px;
}
.loader-shape-3:before,
.loader-shape-3:after {
  position: absolute;
  content: "";
}
@keyframes anm-SL-3-move {
  0% {
    top: 0;
  }
  50% {
    top: -15px;
  }
  100% {
    top: 0;
  }
}
