* {
  font-family: "Inter", sans-serif !important;
}

.graph-data {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.white-box-graph {
  background-color: #ffffff;
  /* border: 1px solid #c8c8c8; */
  border-radius: 10px;
  padding: 20px;
}

.user-avatar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.width-50 {
  min-width: 50px;
}

.width-100 {
  min-width: 100px;
}

.width-150 {
  min-width: 150px;
}

.width-200 {
  min-width: 200px;
}

.width-250 {
  min-width: 250px;
}

.width-300 {
  min-width: 300px;
}

.width-350 {
  min-width: 350px;
}

.width-400 {
  min-width: 400px;
}

.width-450 {
  min-width: 450px;
}

.width-500 {
  min-width: 500px;
}

.width-700 {
  min-width: 700px;
}

.lan-dropdown:hover {
  background-color: #efefef;
}

.lan-dropdown.active {
  background-color: #efefef;
}

.dropdown-arrow {
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  bottom: -5px;
  left: 0px;
  transition: 0.45s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
  margin-left: auto;
}

.dropdown-arrow:before,
.dropdown-arrow:after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 8px;
  height: 0.5px;
  background-color: #8a8a8a;
  transition: 0.45s ease;
  border-radius: 2px;
}

.dropdown-arrow:after {
  position: absolute;
  transform: rotate(90deg);
  top: -4px;
  left: 4px;
}

button[aria-label="true"] .dropdown-arrow:before {
  transform: rotate(-90deg);
}

button[aria-label="true"] .dropdown-arrow:after {
  transform: rotate(180deg);
}

/* ---------------------------- User icons && Table -----------–––––------------------*/

.user-icons {
  white-space: nowrap !important;
}

.user-icons .icon1 {
  width: 25px;
  filter: invert(58%) sepia(48%) saturate(5209%) hue-rotate(328deg) brightness(102%) contrast(101%);
}

.user-icons .user-wallet-icon1 {
  width: 35px;
  filter: invert(58%) sepia(48%) saturate(5209%) hue-rotate(328deg) brightness(102%) contrast(101%);
}

.user-icons .icon2 {
  width: 25px;
  filter: invert(87%) sepia(23%) saturate(5495%) hue-rotate(329deg) brightness(101%) contrast(98%);
}

.user-icons .icon3 {
  width: 25px;
  filter: invert(20%) sepia(13%) saturate(2818%) hue-rotate(180deg) brightness(94%) contrast(90%);
}

.user-icons .icon3Disabled {
  width: 25px;
  filter: grayscale(100%);
  opacity: 30%;
}

.user-icons .icon4 {
  width: 25px;
  filter: invert(87%) sepia(9%) saturate(2887%) hue-rotate(94deg) brightness(86%) contrast(87%);
}

/*---------------------------- TableFooter ----------------------------*/

.TableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 45px;
  padding: 0px 5px;
}

.TableFooter .rowsPerPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.TableFooter .rowsPerPage .label {
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  color: #a3a3a3;
}

.TableFooter .rowsPerPage .border-less-control .MuiSelect-outlined {
  padding: 4px 28px 4px 10px;
  font-size: 15px;
  line-height: 26px;
  color: #3e3e3e;
}

.TableFooter .rowsPerPage .border-less-control .MuiSelect-icon {
  border-color: #3e3e3e;
}

.TableFooter .rowsPerPage .border-less-control .MuiOutlinedInput-notchedOutline {
  display: none;
}

.Notifications-Table td:nth-child(1),
.Notifications-Table th:nth-child(1) {
  text-align: start;

  @media (max-width: 850px) {
    .TableFooter .rowsPerPage {
      margin-bottom: 0;
    }

    .TableFooter {
      flex-direction: column;
      gap: 13px;
    }
  }
}

/* ---------------------------------- popover ----------------------------------*/
.UserDropDown {
  width: 960px;
  min-height: 300px;
}

.UserDropDown::before {
  width: 100vw;
  height: 100vh;
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
}

.UserDropDown .usermenu {
  margin: 5px 0;
  padding: 20px;
}

.rdrDefinedRangesWrapper {
  width: 25%;
}

.rdrCalendarWrapper {
  width: 74%;
}

.UserDropDown .usermenu-button {
  width: 96%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px 0;
}

/* ---------------------------------- Phone - Input ----------------------------------*/

.phone-input-dialog-main .MuiPaper-root {
  overflow: visible;
}

.phone-input-dialog {
  overflow: visible;
}

/* ---------------------------------- Media Queary  ----------------------------------*/

@media only screen and (max-width: 1050px) {
  .UserDropDown {
    width: 850px;
  }

  .rdrDefinedRangesWrapper {
    width: 140px !important;
  }

  .rdrCalendarWrapper {
    width: 620px !important;
  }
}

@media only screen and (max-width: 899px) {
  .UserDropDown {
    width: 800px;
  }

  .rdrCalendarWrapper {
    width: 570px !important;
  }
}

@media only screen and (max-width: 850px) {
  .UserDropDown {
    width: 750px;
  }

  .rdrCalendarWrapper {
    width: 530px !important;
  }
}

@media only screen and (max-width: 800px) {
  .UserDropDown {
    width: 700px;
  }

  .rdrCalendarWrapper {
    width: 480px !important;
  }
}

@media only screen and (max-width: 750px) {
  .UserDropDown {
    width: 650px;
  }

  .rdrDefinedRangesWrapper {
    width: 125px !important;
  }

  .rdrCalendarWrapper {
    width: 450px !important;
  }
}

@media only screen and (max-width: 660px) {
  .UserDropDown {
    width: 550px;
  }

  .rdrDefinedRangesWrapper {
    width: 110px !important;
  }

  .rdrCalendarWrapper {
    width: 360px !important;
  }
}

.input-field-upload {
  text-align: center;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  padding: 20px 0px;
  overflow: hidden;
}

.input-field-upload:hover {
  border-color: #d4582a;
}

.upload-lable {
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.file-upload-icon {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #a3a3a3;
}

.file-upload-icon svg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  color: #d4582a;
}

.form-upload-img {
  width: 150px;
  height: 150px;
}

.form-upload-img img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.error-red-border {
  border-color: #d32f2f !important;
}

.react-tel-input input {
  width: 100% !important;
  border-radius: 10px !important;
  height: 45px !important;
  padding-left: 55px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 10px 0 0 10px !important;
  width: 13% !important;
}

.react-tel-input .flag-dropdown .selected-flag {
  padding: 14px !important;
  width: 48px !important;
  border-radius: 10px 0 0 10px !important;
}

.react-tel-input .flag-dropdown .selected-flag:hover,
.react-tel-input .flag-dropdown .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input:hover input,
.react-tel-input:hover .flag-dropdown {
  border-color: #d4582a !important;
  border-radius: 10px;
}

.react-pdf__Page {
  height: 150px !important;
}

.react-pdf__Page__canvas {
  width: 150px !important;
  height: 150px !important;
  object-fit: fill;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

.status-dropdown {
  text-transform: capitalize;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
  gap: 5px;
  cursor: dateOfBirth;
}

.status-dropdown img {
  width: 7px;
  margin-left: 3px;
  margin-bottom: -3px;
  transform: rotate(270deg);
}

.status-dropdown-menu ul li {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.profile-details img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.upload-documents {
  text-align: center;
  display: flex;
  gap: 30px;
  height: auto;
}

.upload-document-card {
  width: 30%;
  flex-grow: 1;
}

.upload-document-card-data {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.upload-document-card .react-pdf__Page__canvas {
  height: 190px !important;
}

.upload-document-card-data img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 776px) {
  .upload-documents {
    flex-wrap: wrap;
  }

  .upload-document-card {
    width: 100%;
    flex-grow: 1;
  }
}

.profile-details {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px 0px;
}

[aria-label="custom tabs example"] {
  justify-content: flex-start;
  gap: 30px;
}

.otp-field {
  font-size: 2rem !important;
  line-height: 1.2em !important;
  text-align: center !important;
  width: 30px;
  border: none;
  height: 1.437em;
  margin-bottom: 10px;
  font-family: Roboto;
  color: #3e3e3e;
  padding: 4px 0 5px;
  border-bottom: 1px solid #0000006b;
}

.otp-field:focus {
  border: none;
  border-bottom: 2px solid #d4582a;
}

.otp-container {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.MuiStepLabel-label {
  margin-top: 10px !important;
}

hr {
  opacity: 0.5;
}

.table-col.discription-col {
  display: block;
  height: 100%;
}

/* .table-container{
  max-width: 100%;
  overflow: hidden;
}

.table-container .scrollbar-container {
  max-width: 100%;
  overflow: hidden;
}

.table-container table {
  max-width: 100%;
  overflow: hidden;
} */