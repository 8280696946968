@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DynaPuff&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

/*------------------------------------------------------------
Project Name:HR-Clinician;
------------------------------------------------------------*/
/* Variables */
:root {
  --primary: #0097A9;
  --primaryDark: #006976;
  --primaryLight: #F1FCFD;
  --lightText: #A3A3A3;
  --darkText: #3E3E3E;
  --greyText: grey;
  --blackText: #000000;
  --whiteBg: #FFFFFF;
  --bodyBg:#F9F9F9;
  --lightBorderColor: #E9E9E9;
  --darkBorderColor: #D1D1D1;
  --lightWarning: #ECCE62;
  --lightPurple: #9695C5;
  --lightSkyBlue: #B8DEED;
  --colorA7D8ED: #A7D8ED;
  --colorF4E5B0: #F4E5B0;
  --colorC1F0DF: #C1F0DF;
  --colorF6D5CD: #F6D5CD;
  --colorEBEBEB: #EBEBEB;
  --bg-F1F1F1: #F1F1F1;
  --colorB2E0EF: #B2E0EF;
  --color324B58: #324B58;
  --colorFF5353: #FF5353;
  --color9BB6D5: #9BB6D5;
  --colorFDA53D: #FDA53D;
  --color-F8824F: #F8824F;
  --color738EB7: #738EB7;
  --colorFF9330: #FF9330;
  --color9A895D: #9A895D;
  /* --color9B895D: #e9e9ec; */
  --color9B895D: #fbfbff;
}

/* Reset
--------------------------------------------------------------------------------------------------------*/
/*http://meyerweb.com/eric/tools/css/reset/v2.0 | 20110126License:none (public domain)*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}

/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
html, body{font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"; font-size: 15px; line-height: 20px; min-width: 320px; color: var(--darkText); background-color: var(--color9B895D); -ms-overflow-style: scrollbar;}
::-webkit-scrollbar {width: 8px !important;height: 8px !important;}
::-webkit-scrollbar-thumb {border: 0 !important;background-color: #b2b2b2 !important;border-radius: 12px !important;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
ul {list-style-type: none;}
*{outline: none!important;}
a{text-decoration: none!important; transition: all 0.3s; color: var(--primary);}
a:hover{color: var(--primaryDark);}
a.secondary-link{color: var(--lightText);}
a.secondary-link:hover{color: var(--primary);}
.light-text{color: var(--lightText);}
.text-center{text-align: center!important;}
.text-right{text-align: right!important;}
.fw-300{font-weight: 300!important;}
.fw-400{font-weight: 400!important;}
.fw-500{font-weight: 500!important;}
.fw-600{font-weight: 600!important;}
.fw-700{font-weight: 700!important;}
.lnh-16{ line-height: 16px!important; }
.lnh-18{ line-height: 18px!important; }
.lnh-22{ line-height: 22px!important; }
.lnh-26{ line-height: 26px!important; }
.lnh-36{ line-height: 36px!important; }
.lnh-45{ line-height: 45px!important; }
.fs-12{ font-size: 0.8rem!important; line-height: 1.3; }
.fs-13{ font-size: 0.85rem!important; line-height: 1.2; }
.fs-15{ font-size: 1rem!important; line-height: 1.3; }
.fs-18{ font-size: 1.2rem!important; line-height: 1.3; }
.black-text{color: var(--blackText);}
.white-box{background-color: var(--whiteBg); box-shadow: 0 0 10px 1px rgba(0,0,0,0.1); border-radius: 2px; padding: 15px;}
.white-box-table{background-color: var(--whiteBg); box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);}
.upper-case{text-transform: uppercase !important;}
.capitalize{text-transform: capitalize !important;}
/* Common Classes */
.p-5p{ padding: 5px!important; }
.pb-4p{ padding-bottom: 4px!important; }
.pl-4p{ padding-left: 4px!important; }
.pr-4p{ padding-right: 4px!important; }
.mt-2p{ margin-top: 2px!important; }
.mr-2p{ margin-right: 2px!important; }
.mr-4p{ margin-right: 4px!important; }
.ml-4p{ margin-left: 4px!important; }
.mb-4p{ margin-bottom: 4px!important; }
.mt-4p{ margin-top: 4px!important; }
.mt-5p{ margin-top: 5px!important; }
.mb-5p{ margin-bottom: 5px!important; }
.mb-6p{ margin-bottom: 6px!important; }
.mt-4p{ margin-top: 4px!important; }
.mt-6p{ margin-top: 6px!important; }
.mt-7p{ margin-top: 7px!important; }
.mt-8p{ margin-top: 8px!important; }
.mt-14p{ margin-top: 14px!important; }
.mt-15p{ margin-top: 15px!important; }
.mb-15p{ margin-bottom: 15px!important; }
.mr-16p{ margin-right: 16px!important; }
.mr-24p{ margin-right: 24px!important; }
.max-400{max-width: 400px !important;}
.max-50{max-width: 50px !important;}
.whiteBg{ background: var(--whiteBg)!important; }
.bg-F1F1F1{ background: var(--colorF1F1F1)!important; }
.color-F8824F{ color: var(--color-F8824F)!important; }
.bg-738EB7{ background: var(--color738EB7)!important; }
.img-fluid{ max-width: 100%!important; }
.grey-box{ border-radius: 5px; box-sizing: border-box; padding: 8px 15px; background: var(--bodyBg); border: 1px solid var(--darkBorderColor); }
.grey-bordered-box{ border-radius: 5px; box-sizing: border-box; border: 1px solid var(--darkBorderColor); }
.dual-btn-wrapper{ display: flex; align-items: center; background: var(--whiteBg); box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1); border-radius: 5px; }
.vertical-separator{ border-left: 1px solid var(--darkBorderColor); display: block; width: 1px; height: calc(100% - 20px); }
.dashboard-images{ margin-top: 8px; max-width: 100%; width: 100%; max-height: 430px;}
.flex-wrap{ flex-wrap: wrap!important; }
.whitespace-nowrap{ white-space: nowrap!important; }
.flex-grow1{ flex-grow: 1!important; }
.rounded{ border-radius: 50%!important; }
.w-50{width: 50%;}
.w-100{width: 100%;}
.w-80p{ width: 80px; }
.w-100p{ min-width: 100px; width: 100px; }
.minW-auto{ min-width: auto!important; }
.minW-100p{ min-width: 100px; width: 100px; }
.minW-90p{ min-width: 90px; width: 90px; }
.minW-120p{ min-width: 120px; width: 120px; }
.minW-300p{ min-width: 300px; width: 300px; }
.minW-200p{ min-width: 200px; width: 200px; }
.min-h-100{ min-height: 100%!important; }
.key-data{ color: var(--lightText); line-height: 19px!important; display: block; }
.value-data{ color: var(--darkText); line-height: 19px!important; display: block; font-weight: 700; }
.black-text{ color: var(--blackText)!important; } 
.dark-text{ color: var(--darkText)!important; } 
.error-text{ color: var(--colorFF5353)!important; }
.main-color{ color: #244264!important; border-color: #244264!important; }
.warning-light.MuiChip-filled{ color: var(--whiteBg)!important; background: var(--lightWarning)!important; }
.purple-light.MuiChip-filled{ color: var(--whiteBg)!important; background: var(--lightPurple)!important; }
.skyblue-light.MuiChip-filled{ color: var(--whiteBg)!important; background: var(--lightSkyBlue)!important; }
.bg-FF9330.MuiChip-filled{ color: var(--whiteBg)!important; background: var(--colorFF9330)!important; }
.bg-9A895D.MuiChip-filled{ color: var(--whiteBg)!important; background: var(--color9A895D)!important; }
.border-top1{ border-top: 1px solid var(--darkBorderColor); }
.border-bottom1{ border-bottom: 1px solid var(--darkBorderColor); }
.MuiDialogActions-root.pt-0{ padding-top: 0!important; }
.MuiDialogContent-root.pb-2{ padding-bottom: 20px!important; }
.link-secondary{ font-size: 1rem; line-height: 1.2; color: var(--darkText); }
.link-secondary:hover{ color: var(--primary); }
.grouped-option-menu .MuiMenuItem-root{ color: var(--lightText); }
.MuiMenuItem-root.grouped-option{ padding-left: 24px; }
.mt--2p.MuiFormControl-root{ margin: -2px 0 0; }
.inner-form { margin: auto 0;}
.inner-form .MuiGrid-container.MuiGrid-spacing-xs-3{ margin-left: -23px; }
.inner-form .MuiGrid-container.MuiGrid-spacing-xs-2{ margin-left: -15px; }
.inner-form .MuiGrid-container.MuiGrid-spacing-xs-1{ margin-left: -7px; }
.grouped-option-menu .MuiMenuItem-root{ color: var(--lightText); }
.MuiMenuItem-root.grouped-option{ padding-left: 24px; }
.solo-check.MuiFormControlLabel-root{width: 20px; height: 20px; margin: 0; }
body, #root{height: 100%; }
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none;-moz-appearance: none;appearance: none;margin: 0;}
input[type=number] {-moz-appearance:textfield;}
.word-break {word-break: break-all;}

/* Loader
****************************************************************************/
.loader-wrapper{ position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999;display: flex; align-items: center; justify-content: center; }


/* main content *//* Main Content 
****************************************************************************/
.main-content{padding: 30px; transition: all 0.3s ease-in-out;}

/* Login Page
****************************************************************************/
.page-with-box { background: var(--primary); min-height: 100vh; display: flex;}
.page-with-box-container{display: flex; min-height: 100%; flex-direction: column; padding: 20px 0 70px; box-sizing: border-box; max-width: 400px; margin: 0 auto;}
.page-with-box-header{padding: 0 0 20px;}
.page-with-box-content{background: var(--whiteBg); border-radius: 10px;}
.page-with-box-content-right{padding: 20px;}
.page-with-box-content-heading{text-align: center};

.page-with-box .slick-slider{height: 200px;}
.page-with-box .slick-list{border-radius: 10px 10px 0 0;}
.page-with-box .slick-list, .page-with-box .slick-track, .page-with-box .slick-slide, .page-with-box .slick-slide div{height: 100%;}
.page-with-box .slick-track{display: flex;}
.page-with-box .slick-slide img{width: 100%; height: auto; object-fit: cover; display: block;}
.page-with-box .slick-dots{position: absolute; bottom: 10px;  width: 100%; left: 0; display: flex!important; justify-content: center;}
.page-with-box .slick-dots li{width: 18px; height: 18px; border:solid 1px transparent; border-radius: 18px; display: flex; align-items: center; justify-content: center; transition: all 0.3s;}
.page-with-box .slick-dots li.slick-active{border-color: var(--whiteBg);}
.page-with-box .slick-dots button{width: 10px; height: 10px; padding: 0; font-size: 0; border-radius: 10px; border:0; background: var(--whiteBg); outline: none!important; cursor: pointer;}

/* over layer */
/* .over-layer{position: fixed;background: rgba(0,0,0,0.45);top: 0;right: 0;left: 0;bottom: 0;z-index: 0;opacity: 0;visibility: hidden;transition: all 0.2s ease;} */
.sidebar-over-layer{ z-index: 2; }
/* .show-search .over-layer, .hide-sidebar .over-layer{opacity: 1;visibility: visible;} */
.show-search .header-search{margin-top: 0;}
/* .hide-sidebar .sidebar .main-navigation li.has-menu.open {background-color: transparent !important;} */
.hide-sidebar .sidebar .main-navigation .nav-item svg {margin-right: 0;} 
.show-search .header-search{margin-top: 0;}

/* page-title-with-action */
.page-title-with-action{ display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }
/* .page-title-with-action .title{ color: var(--darkText); font-family: 'Kanit', sans-serif }  */
.page-title-with-action .title{ color: var(--darkText); font-family: Arial, Helvetica, sans-serif;} 
.page-title-with-action .label{ color: var(--greyText); } 
.header-logo-font {font-family: Arial, Helvetica, sans-serif}
/* PageTitle With Action 
****************************************************************************/
.page-title-with-action{ display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; margin-bottom: 0; }
.page-title-with-action .title{ color: var(--darkText); margin-right: 20px; margin-bottom: 16px; } 
.page-title-with-action .title span{font-weight: 300;}
.page-actions{ display: flex; justify-content: flex-end; align-items: center; margin-bottom: 16px; }
.page-actions button .MuiBadge-root{position: absolute; right: 0; top: 0;}
.page-actions .icon-btn{padding: 6px; min-width: auto;}
.page-actions-btn1{ display: flex; justify-content: flex-end; align-items: center; margin-bottom: 16px; padding: 6px; margin-right: 710px;}

/* Tabs inside vertical tabs
****************************************************************************/
.vertical-tabs .tab-block .MuiTabs-scroller{ height: 42px; position: relative; }
.vertical-tabs .tab-block .tab-wrapper{ border-bottom: 1px solid var(--darkBorderColor);  }
.vertical-tabs .tab-block .tab-wrapper .MuiTabs-root{ margin-bottom: -1px; }
.vertical-tabs .tab-block .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator{ bottom: 0; z-index: 1; }
.vertical-tabs .tab-block .MuiTabs-root .MuiTabs-scroller .MuiTab-root{ line-height: 1.2; font-size: 1.2rem; color: var(--darkText); padding: 10px 0; letter-spacing: 0; font-weight: 400; min-height: 42px; min-width: auto; }
.vertical-tabs .tab-block .MuiTabs-root .MuiTabs-scroller .MuiTab-root.Mui-selected{ color: var(--primary); }
.vertical-tabs .page-title-with-action .title{ flex-grow: 100; }


/* analysis-card */
.analysis-card{ padding: 20px 10px 20px 23px; display: flex; overflow: hidden; }
.analysis-card .analysis-card-icon{ display: block; border-radius: 50%; min-width: 46px; width: 46px; height: 46px; position: relative; margin-right: 18px; }
.analysis-card .analysis-card-icon:after{ content: ""; position: absolute; top: 0; bottom: 0; right: 0; right: 0; display: block; border-radius: 50%; min-width: 46px; width: 46px; height: 46px; transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }
.analysis-card .analysis-card-icon .icon{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 22px; height: 22px;z-index: 2;transition: all 0.4s ease-in-out; }
.analysis-card .analysis-card-detail .label{ display: block; color: var(--lightText);z-index: 2; position: relative; transition: all 0.4s ease-in-out; }
.analysis-card .analysis-card-detail .data{ font-weight: 600; line-height: 32px;z-index: 2; position: relative; }
.analysis-card .analysis-card-icon.purple, .analysis-card .analysis-card-icon.purple:after { background: #FBE5FF; }
.analysis-card .analysis-card-icon.green, .analysis-card .analysis-card-icon.green:after{ background: #DAFFF2; }
.analysis-card .analysis-card-icon.red, .analysis-card .analysis-card-icon.red:after{ background: #FFE8E8; }
.analysis-card .analysis-card-icon.blue, .analysis-card .analysis-card-icon.blue:after{ background: #E7F3FF; }
.analysis-card:hover .analysis-card-icon:after{ transform: scale(100); z-index: 1; }
.analysis-card:hover .analysis-card-icon{ transform: scale(2); -webkit-transform: scale(2); -moz-transform: scale(2); -ms-transform: scale(2); -o-transform: scale(2); }
.analysis-card:hover .analysis-card-detail .label{ color: var(--darkText) }


/* market-result-card */
.market-result .analysis-card {min-height: 200px;}
.market-result .analysis-card .analysis-card-detail{width: 100%; text-align: center;}
.market-result .analysis-card {padding: 10px}

.profit-loss .analysis-card{min-height: auto !important;}

/* Tool-Tip  
****************************************************************************/
.MuiTooltip-tooltip {background-color: var(--lightText) !important;font-size: 0.85rem !important;}
.notification-icon-column{ width: 10px;}
.chips-wrapper .MuiButtonBase-root{max-width: 560px;}

.yellow.MuiChip-filled{ background: var(--lightWarning)!important; color: var(--whiteBg)!important; }
.pink.MuiChip-filled{ background: var(--pink)!important; color: var(--whiteBg)!important; }
.black.MuiChip-filled{ background: var(--blackText)!important; color: var(--whiteBg)!important; }

.no-records-found {
    color: #00000042;
    font-size: 1.2rem;
    font-weight: 600;
 } 

@media (min-width: 600px){
    /* .main-content{padding: 85px 20px 20px;} */
}


@media (max-width: 599px){
    .main-content > section > .page-title-with-action .page-actions .MuiButton-contained.MuiButton-sizeLarge{ padding-top: 10px; padding-bottom: 10px; }
    .main-content > section > .page-title-with-action .page-actions .MuiButton-outlined.MuiButton-sizeLarge{ padding-top: 9px; padding-bottom: 9px; }
    .main-content > section > .page-title-with-action .page-actions .MuiButton-outlined.MuiButton-sizeLarge.icon-btn{ padding: 1px; }
    .main-content > section > .page-title-with-action .page-actions .MuiButton-contained.MuiButton-sizeLarge.icon-btn{ padding: 2px; }
}

@media (max-width: 900px){
    /* .main-content{padding: 102px 20px 20px 260px;} */
    /* .hide-sidebar .main-content{padding: 112px 20px 20px 20px;} */
    /* .sidebar{ width: 50px !important; } */
    .sidebar .sidebar-logo h1{ display: none !important; }
    /* .hide-sidebar .sidebar{ margin-left: -300px; } */
    /* .hide-sidebar .over-layer{opacity: 0;visibility: hidden;} */

}


@media (min-width: 1200px){
    /* .main-content{padding: 102px 30px 30px 260px;} */
    /* .hide-sidebar .main-content{padding: 102px 30px 30px 30px;} */
}

.disable-commission-done-icon svg{
    pointer-events: none;
    cursor: not-allowed;
    color: #00000042;

}



.flex {display: flex; flex-direction: column; justify-content: center; align-items: center;}

@keyframes blink {
    0% {
        opacity: 0%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }

}